import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import Button from '@mui/material/Button';
import { makeStyles } from '@material-ui/styles';
import DeleteIcon from '@mui/icons-material/Delete';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import FolderIcon from '@mui/icons-material/Folder';
import { maxWidth } from '@mui/system';
import ButtonBase from '@mui/material/ButtonBase';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Link, useLocation } from 'react-router-dom';


import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  });
  
  
  let data = []
  
  for (let i = 1; i <= 12; i++){
    data.push({level: i, members: 2, totalStake: 10})
  }



  function generate(element) {
      return [0, 1, 2].map((value) =>
        React.cloneElement(element, {
          key: value,
        }),
      );
    }
  
    const Demo = styled('div')(({ theme }) => ({
      backgroundColor: 'transparent',
    }));
  
  
    const useStyles = makeStyles(theme => ({
      root: {
        height: '100%',
        borderBottomRightRadius: '40px',
        borderRadius: '5px'
      },
      content: {
        alignItems: 'center',
        display: 'flex'
      },
      title: {
        fontWeight: 'bold',
        color: '#fff'
      },
      subItemtitle: {
        color: '#eee',
        opacity: '0.5',
        fontSize: '8px'
      },
      
      icon: {
        height: 32,
        width: 32
      },
      cardBackground: {
        backgroundColor: '#f0f5f9',
        maxWidth: '300px'
        //padding: 2
      },
      progressBar:{
        width: '50%',
        maxWidth: '200px',
        margin: 'auto'
      }
    }));

    const text = {
        color: "#eee",
        opacity: '0.7',
        fontSize: '11px',
        maxWidth: '150px'
    };

    const subItemtitle = {
      color: '#eee',
      fontSize: '13px'
    };
  
  const TeamOverview = (props) => {
      const { className, tileDilogue, onClicked, ...rest } = props;
      const classes = useStyles();
      const [dense, setDense] = React.useState(false);
      const [secondary, setSecondary] = React.useState(false);
      const location = useLocation();
      const typeofParam = location.pathname.replace('/', '').split("/");

      useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

      return (
      <>
      {data.map((item, index) => {
        return (
            <Paper key={index}
            style={{background: 'transparent', border: '1px solid  rgb(0 0 0 / 14%)'}}
                sx={{
                p: 0,
                margin: 'auto',
                maxWidth: 500,
                marginTop: index !== 0 ? '10px': 0,
                marginBottom: index == data.length - 1 ? '70px' : 0,
                flexGrow: 1
                }}
            >
                <Grid container spacing={2}>
                <Grid item xs={12} sm container>
                    <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs>
                        <Demo>
                            <List dense={dense}>
                                <ListItem
                                    secondaryAction={
                                    <IconButton edge="end" aria-label="delete">
                                        <Link to={"/teaminfo/"+item.level+"/"+typeofParam[typeofParam.length - 1]}>
                                        <KeyboardArrowRightIcon style={{color: '#fff'}}/>
                                        </Link>
                                    </IconButton>
                                    }
                                >
                                    <ListItemText
                                    className={classes.title}
                                    primary={`Level ${item.level}`}
                                    secondary={
                                        <>
                                            <Typography style={{color: '#fff', fontSize: 12}} variant="subtitle2">{`${item.members} Team Members`}</Typography>
                                            <Typography style={{color: '#fff', fontSize: 12}} variant="subtitle2">{`${item.totalStake} HEZ Staked`}</Typography>
                                        </>
                                    }
                                    secondaryTypographyProps={{style: text}}
                                    />
                                </ListItem>
                                
                            </List>
                        </Demo>
                    </Grid>
                    </Grid>
                </Grid>
                </Grid>
            </Paper>
            )
        })}
      </>
      )
  }
  
  export default TeamOverview

