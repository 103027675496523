import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import Button from '@mui/material/Button';
import { makeStyles } from '@material-ui/styles';
import DeleteIcon from '@mui/icons-material/Delete';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import FolderIcon from '@mui/icons-material/Folder';
import { maxWidth } from '@mui/system';
import ButtonBase from '@mui/material/ButtonBase';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import GridTable from '@nadavshaar/react-grid-table';
import { Link, useLocation } from 'react-router-dom';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';

import secureAxios from '../../secureAxios.js';
import IBackdrop from '../backdrop.js';

import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";



const useStyles = makeStyles(theme => ({
      root: {
        height: '100%',
        borderBottomRightRadius: '40px',
        borderRadius: '5px'
      },
      content: {
        alignItems: 'center',
        display: 'flex'
      },
      title: {
        fontWeight: 'bold',
        color: 'rgb(33, 43, 54)',
        fontSize: '10px'
      },
      subItemtitle: {
        color: '#eee',
        opacity: '0.5',
        fontSize: '8px'
      },
      
      icon: {
        height: 32,
        width: 32
      },
      cardBackground: {
        backgroundColor: '#f0f5f9',
        maxWidth: '300px'
        //padding: 2
      },
      progressBar:{
        width: '50%',
        maxWidth: '200px',
        margin: 'auto'
      }
    }));

    const text = {
        color: "#eee",
        opacity: '0.7',
        fontSize: '11px',
        maxWidth: '150px'
    };



  const Status = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
    return (
        <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
            <span className='rgt-text-truncate' style={{marginLeft: 10, color: data.isActive ? '#008000': '#FF0000'}}>{data.isActive ? "Active": "In Avtive"}</span>
        </div>
    )
}
  
  const columns = [
    {
      id: 1, 
      field: 'username', 
      label: 'Username'
    }, 
    {
      id: 2, 
      field: 'stake', 
      label: 'Stake'
    },
    {
      id: 3, 
      field: 'address', 
      label: 'address'
    }
  ]

  const data = {
    total: {
      member: 0,
      stake: 0,
      userDetails: []
    },
    direct: {
      member: 0,
      stake: 0,
      userDetails: []
    }
    
  }


  
  const TeamInfo = (props) => {
      const { className, tileDilogue, onClicked, ...rest } = props;
      const classes = useStyles();
      const [dense, setDense] = React.useState(false);
      const [secondary, setSecondary] = React.useState(false);
      const location = useLocation();
      const typeofParam = location.pathname.replace('/', '').split("/");

      const [open, setOpen] = React.useState(false);
      const [statements, setStatements] = React.useState(data);

      useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

      const memberDetails = () => {
        setOpen(true);
        secureAxios
            .get(`${process.env.REACT_APP_SERVICE_PYTHON_API_URL}/user/team/list`).then(response => {
              setOpen(false);
              
              setStatements(response.data);
              
            }).catch(error => {
              setOpen(false);
              if (error.response.data?.non_field_errors){
                alert(error.response.data?.non_field_errors)
              }
              else{
                alert(error.response.data?.detail)
              }
    
            })
      }

      React.useEffect(() => {
        memberDetails()
        return
      }, []);


      return (
      <>  
            <IBackdrop isOpen={open} msg={"Signin progress"}/>

            <List style={{marginTop: '0px'}} dense={dense}>
              <ListItem
                  secondaryAction={
                    <Grid container direction="row" alignItems="center">
                      <Typography style={{color: 'rgb(33, 43, 54)', fontSize: 12}} variant="subtitle2">{statements?.[typeofParam[1]].member}</Typography>
                      <Typography style={{color: 'rgb(33, 43, 54)', fontSize: 5, marginLeft: '10px'}} variant="subtitle2"><PeopleRoundedIcon/></Typography>
                    </Grid>
                    
                  }
              >
                  <ListItemText
                  className={classes.title}
                  primaryTypographyProps={{style: {fontSize: '12px'}}}
                  primary={`Total ${typeofParam[1] == 'total' ? '' : 'Direct'} Referral`}
                  />
              </ListItem>

              <ListItem
                  secondaryAction={
                    <Grid container direction="row" alignItems="center">
                      <Typography style={{color: 'rgb(33, 43, 54)', fontSize: 12}} variant="subtitle2">{statements?.[typeofParam[1]].stake.toFixed(6)}</Typography>
                      <Typography style={{color: 'rgb(33, 43, 54)', fontSize: 5, marginLeft: '10px'}} variant="subtitle2"><AccountBalanceWalletOutlinedIcon/></Typography>
                    </Grid>
                    
                  }
              >
                  <ListItemText
                  className={classes.title}
                  primaryTypographyProps={{style: {fontSize: '12px'}}}
                  primary={`Total ${typeofParam[1] == 'total' ? '' : 'Direct'} Referral Stake`}
                  />
              </ListItem>
              
          </List>
           <GridTable style={{marginBottom: '70px'}} columns={columns} rows={statements?.[typeofParam[1]].userDetails}/>
      </>
      )
  }
  
  export default TeamInfo

