import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

import { SET_SCREEN_SIZE } from '../redux/actions/types';
import { useDispatch, useSelector } from 'react-redux';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import { Redirect } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { LOGIN, LOGOUT, SET_ADMIN } from '../redux/actions/types';
import { clear } from '@testing-library/user-event/dist/clear';
import { Outlet } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  shiftContent: {
    paddingLeft: 190
  },
  content: {
    height: '100%',
    paddingLeft: '30px',
    paddingRight: '30px',
    marginTop: '50px',
  }
}));

const Main = props => {
  const { children } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const application  = useSelector(state => ({
    ...state.application
  }));
  const size = 700;
  let Navigate = useNavigate()




  // React.useEffect(() => {
  //   if (application.isAuthenticated === false || application.isAuthenticated === null){
  //     history.push("/")
  //   }
  // }, []); 


  const disconnect = async () => {
    console.log("disconnected")
    alert("disconnected")
    //await web3ModalConfig.clearCachedProvider();
    window.localStorage.clear();

  };

  const Logout = () => {
    alert("logout")
    dispatch({
      type: LOGIN,
      payload: {
        profile: {},
        isAuthenticated: false,
        accessToken: {},
        groups: {}
      }
    });
    Navigate.push("/")
  }




  return (
    <div
      className={clsx({
        [classes.root]: true
      })}
    >
      <Header/>
      <Sidebar/>
      <main className={classes.content}>
        <Outlet/>
      </main>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node
};

export default Main;