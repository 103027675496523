import React, { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import './styles.css';

const CoinTabs = () => {
  const [value, setValue] = useState(0);
  const [coinData, setCoinData] = useState([]); // Initialize an empty array for coin data
  const [coinValue, setCoinValue] = useState('');
  const [coinLimit, setCoinLimit] = useState('');
  const [password, setPassword] = useState('');
  const [dollar_value, setDollarValue] = useState(0);

  useEffect(() => {
    // Simulate fetching data from the server
    const fetchDataFromServer = async () => {
      try {
        // Replace this with your actual API endpoint
        const response = await fetch(`${process.env.REACT_APP_SERVICE_PYTHON_API_URL}/coins`);
        const data = await response.json();
        setCoinData(data.coins);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchDataFromServer(); // Call the function to fetch data when the component mounts
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (coinData.length > 0) {
      const selectedCoin = coinData[value];
      setCoinValue(selectedCoin.dollar_value);
      setCoinLimit(selectedCoin.withdraw_minimum);
      setDollarValue(selectedCoin.wallet_dollar_value);
    }
  }, [value, coinData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'coinValue':
        setCoinValue(value);
        break;
      case 'coinLimit':
        setCoinLimit(value);
        break;
      case 'password':
        setPassword(value);
        break;
      case 'dollar_value':
        setDollarValue(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (password == '' || coinValue < 0 || coinLimit < 0 || dollar_value < 0){
        alert("Required fields needed")
        return false
    }

    // Send the updated coin data to a server or perform any other necessary action

    // You can use Axios or fetch to send the data to the server
    // Example using fetch:
    const selectedCoin = coinData[value]; // Get the selected coin's data
    fetch(`${process.env.REACT_APP_SERVICE_PYTHON_API_URL}/updateCoin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        coinValue:parseFloat(coinValue),
        coinLimit:parseFloat(coinLimit),
        coinCode:selectedCoin.coin_code,
        password: password,
        swap_dollar_value:dollar_value
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.success){
            alert(`${selectedCoin.coin_code} updated successfully:`);
        }
        else{
            alert('Something went wrong')
        }
      })
      .catch((error) => {
        console.error(`Error updating ${selectedCoin.coin_code}:`, error);
      });
  };

  return (
    
    <div className={'jy'}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        {coinData.map((coin, index) => (
          <Tab label={coin.coin_code} key={index} />
        ))}
      </Tabs>

      <div>
        <h2>Update {coinData[value]?.coin_code}</h2>
        <form onSubmit={handleSubmit}>
          <TextField
          
            label="Coin Value"
            name="coinValue"
            value={coinValue}
            onChange={handleInputChange}
            type="number"
            variant="outlined"
            fullWidth
            required
          />
          <TextField
            label="Coin Limit"
            name="coinLimit"
            value={coinLimit}
            style={{marginTop: '30px'}}
            onChange={handleInputChange}
            type="number"
            variant="outlined"
            fullWidth
            required
          />

        <TextField
            label="Password"
            name="password"
            value={password}
            style={{marginTop: '30px'}}
            onChange={handleInputChange}
            type="text"
            variant="outlined"
            fullWidth
            required
          />
          <TextField
            label="Dollar Value"
            name="dollar_value"
            value={dollar_value}
            style={{marginTop: '30px'}}
            onChange={handleInputChange}
            type="text"
            variant="outlined"
            fullWidth
            required
          />
          <Button style={{marginTop: '30px'}} type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </form>
      </div>
    </div>
  );
};

export default CoinTabs;
