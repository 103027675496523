import React from 'react';
import { Routes ,Route, Navigate } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout } from './layouts';
import { Dashboard, CapitalStateMent, RewardStateMent, WithdrawalStateMent, TeamRewardStateMent, Swap} from './views/Dashboard';
import { Users, TeamOverview, TeamInfo } from './views/Users';
import Home from './views/Home';
import SignUp from './views/signup';
import CoinTabs from './views/update_price';
import Wallet from './views/wallet';
import Main from './views/Main';
import Notifications from './views/Users/notifications';

const routes = () => {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/login" element={<Navigate replace to="/" />} />
      <Route path="/signup" element={<SignUp />} />
      
      <Route element={<MainLayout />}>
        <Route exact path="/home" element={<Home />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/swap" element={<Swap />} />
        <Route path="/deposit-statement" element={<CapitalStateMent />} />
        <Route path="/rewards-statement" element={<RewardStateMent />} />
        <Route path="/transactions" element={<WithdrawalStateMent />} />
        <Route path="/team-rewards-statement" element={<TeamRewardStateMent />} />
        <Route path="/users" element={<Users />} />
        <Route path="/teamoverview/:typeof" element={<TeamInfo />} />
        <Route path="/teaminfo/:level/:typeof" element={<TeamInfo />} />
        <Route path="/user/wallet" element={<Wallet />} />
        <Route path="/notifications" element={<Notifications />} />
        <Route path="/coin_update" element={<CoinTabs />} />
      </Route>
    </Routes>
  );
};

export default routes;