export const loadState = () => {
    try {
      const serializedState = localStorage.getItem('application_state');
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (err) {
      return undefined;
    }
  };
  
  export const saveState = state => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem('application_state', serializedState);
    } catch (err) {
      //Ignore write errors
    }
  };
  
  export const setItem = (name, value) => {
    localStorage.setItem(name, value);
  };
  
  export const getItem = name => {
    return localStorage.getItem(name);
  };
  
  export const removeItem = name => {
    localStorage.removeItem(name);
  };
  
  export const clear = () => {
    localStorage.clear();
    sessionStorage.clear();
  };