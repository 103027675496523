import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


const TransferDialog = ({ open, onClose, onTransfer }) => {
  const [addressOrPin, setAddressOrPin] = useState('');
  const [amount, setAmount] = useState('');
  const [pin, setPin] = useState('');
  const [coin_code, setCoinCOde] = React.useState('usdt');

  const handleCoinChange = (event) => {
    setCoinCOde(event.target.value);
  };

  const handleClose = () => {
    setAddressOrPin('');
    setAmount('');
    setPin('');
    onClose();
    setCoinCOde('usdt');
  };

  const handleTransfer = () => {
    // Perform transfer action
    onTransfer({ addressOrPin, amount, pin, coin_code });
    // Optionally, you can reset the fields after submission
    setAddressOrPin('');
    setAmount('');
    setPin('');
    setCoinCOde('usdt');
  };

  return (
    <Dialog className='dilog_popup' open={open} onClose={handleClose}>
      <DialogTitle>Transfer Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To transfer please enter your amount
        </DialogContentText>

        <TextField
          margin="dense"
          id="addressOrPin"
          label="Address or user id"
          type="text"
          fullWidth
          variant="standard"
          value={addressOrPin}
          onChange={(event) => setAddressOrPin(event.target.value)}
        />

        <TextField
          margin="dense"
          id="amount"
          label="Amount"
          type="number"
          fullWidth
          variant="standard"
          value={amount}
          onChange={(event) => setAmount(event.target.value)}
        />

        <TextField
          margin="dense"
          id="pin"
          label="Secret Pin"
          type="password"
          fullWidth
          variant="standard"
          value={pin}
          onChange={(event) => setPin(event.target.value)}
        />

        <InputLabel style={{marginTop: '10px'}} id="select-label">Select Coin</InputLabel>
          <Select
              labelId="select-label"
              value={coin_code}
              style={{
                marginTop: '10px',
                borderBottom: '1px solid #000'
              }}
              fullWidth
              onChange={handleCoinChange}
              displayEmpty
              aria-label="Select an Option"
              MenuProps={{
                anchorOrigin: {
                  vertical: 'top', // Position the anchor menu at the top
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
                PaperProps: {
                  style: {
                    maxWidth: '300px', // Set a maximum width for the menu (adjust as needed)
                    width: '100%',
                    marginTop: '-50px', // Add a negative margin-top of -50px to the entire menu box
                  },
                },
              }}
        

            > 
              <MenuItem value={'usdt'}>USDT</MenuItem>
              <MenuItem value={'btt'}>BTT</MenuItem>
            </Select>

      </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleTransfer}>Submit</Button>
        </DialogActions>
    </Dialog>
  );
};

export default TransferDialog;
