import { useConnect, useDisconnect, useAccount, useEnsAvatar, useEnsName, useWalletClient, useSendTransaction } from 'wagmi';
import { createPublicClient, http } from 'viem';
import { erc20Abi } from 'viem';
import { parseEther } from 'ethers';
import { bsc } from 'wagmi/chains';

export function connectWallet () {

}

export default function transferTokens () {


}

const publicClient = createPublicClient({
  chain: bsc,
  transport: http(),
});

const calculateGasMargin = (value, margin = 1000n) => {
  return (value * (10000n + margin)) / 10000n;
};

async function sendTransaction(walletClient, contractAddress, recipient, _amount) {

    const amount = `${_amount}`;
  // const estimatedGas = await publicClient.estimateGas({
  //   abi: erc20Abi,
  //   address: contractAddress,
  //   functionName: 'transfer',
  //   args: [recipient, parseEther(amount)],
  //   account: walletClient.account,
  // });

  // console.log(estimatedGas)


  // const gasPrice = await publicClient.getGasPrice();

  // console.log(gasPrice)


  try {
    const transaction = await walletClient.writeContract({
      abi: erc20Abi,
      address: contractAddress,
      account: walletClient.account,
      functionName: 'transfer',
      args: [recipient, parseEther(amount)],
      value: 0n,
      gas: calculateGasMargin(53000n),
      gasPrice: 1000000000n,
    });

    if (transaction) {
      const receipt = await publicClient.waitForTransactionReceipt({
        hash: transaction,
      });

      if (receipt.status === 'success') {
        return { transactionHash: transaction };
      } else {
        throw new Error('Balance not avialable');
        return { error: true };
       
      }
    }
  } catch (error) {
    console.error('Error in transaction:', error);
    throw new Error(error);
  }
}

export function useWalletOperations() {
  const { connect, connectors } = useConnect();
  const { address, isConnected } = useAccount();
  const { disconnect } = useDisconnect();
  const { data: ensName } = useEnsName({ address });
  const { data: ensAvatar } = useEnsAvatar({ name: ensName || undefined });
  const { data: walletClient } = useWalletClient();

  const contractAddress = "0x55d398326f99059fF775485246999027B3197955";
  const recipient = "0x3425C387a05D1cc28b83B4e2cC598804aa371b90";

  const handleSendTransaction = async (amount) => {

    if (isConnected) {
      return await sendTransaction(walletClient, contractAddress, recipient, amount);
    }
    return { error: "Not connected" };
  };

  return {
    isConnected,
    address,
    ensAvatar,
    connect,
    disconnect,
    handleSendTransaction,
  };
}

