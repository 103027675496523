import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import Button from '@mui/material/Button';
import { makeStyles } from '@material-ui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import Moment from 'moment';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import FolderIcon from '@mui/icons-material/Folder';
import { maxWidth } from '@mui/system';
import ButtonBase from '@mui/material/ButtonBase';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Link, useLocation } from 'react-router-dom';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import secureAxios from '../../secureAxios.js';
import IBackdrop from '../backdrop.js';

import Pagination from '@mui/material/Pagination';


import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  });
  
  
  
    const Demo = styled('div')(({ theme }) => ({
      backgroundColor: 'transparent',
    }));
  
  
    const useStyles = makeStyles(theme => ({
      root: {
        height: '100%',
        borderBottomRightRadius: '40px',
        borderRadius: '5px'
      },
      content: {
        alignItems: 'center',
        display: 'flex'
      },
      title: {
        fontWeight: 'bold',
        color: 'rgb(33, 43, 54)'
      },
      subItemtitle: {
        color: '#eee',
        opacity: '0.5',
        fontSize: '8px'
      },
      
      icon: {
        height: 32,
        width: 32
      },
      cardBackground: {
        backgroundColor: '#f0f5f9',
        maxWidth: '300px'
        //padding: 2
      },
      progressBar:{
        width: '50%',
        maxWidth: '200px',
        margin: 'auto'
      },
      pan: {
        boxShadow:  'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;',
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
        background: 'rgb(255, 255, 255)',
      }
    }));

    const text = {
        color: "#eee",
        opacity: '0.7',
        fontSize: '11px',
        maxWidth: '150px'
    };

    const subItemtitle = {
      color: '#eee',
      fontSize: '13px'
    };


  const get_formatter_text = (reason) => {
    let l;
    switch(reason){
      case 'withdrawan':
        l =  "Withdrawal"
        break
      case "transfer_send":
        l = "Transferred Send"
        break
      case "transfer_received":
        l = "Transfer Received"
        break
      case "team":
        l = "Universal"
        break
      case "direct":
        l = "Direct reffer"
        break
      case "convertreceived":
        l = "Convert received"
        break
      case "convertwithdrawan":
        l = "Converted"
        break
      case "add":
        l = "Buy"
        break
      case "unlock":
        l = "Unlock"
        break
      case "withdrawan_refund":
        l = "Withdraw Refund"
        break
      case "nft_reddem":
        l = "Reddem"
        break
      case "nft_monthly":
        l = "Monthly NFT Royalty"
        break
      default:
        l = "Withdraw (Restake)"
    }
    return l
  }

  const get_formatter_text2 = (reason) => {
    let l;
    switch(reason){
      case "transfer_send":
        l = "at"
        break
      case "transfer_received":
        l = "at"
        break
      default:
        l = "at"
    }
    return l
  }
  
  const WithdrawalStateMent = (props) => {
      const { className, tileDilogue, onClicked, ...rest } = props;
      const classes = useStyles();
      const [dense, setDense] = React.useState(false);
      const [secondary, setSecondary] = React.useState(false);
      const [open, setOpen] = React.useState(false);
      const [statements, setStatements] = React.useState([]);
      const location = useLocation();
      const typeofParam = location.pathname.replace('/', '').split("/");
      const [noData, setNoData] = React.useState(true);
      const [page, setPage] = React.useState(1);
      const [total, setTotal] = React.useState(0);


      

      useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

      const notify = () => alert("Copied Successfully");

      const handlePageChange = (event, newPage) => {
        setPage(newPage); // Update the page number when the user changes the page
      };
      
      const withdrawanDetails = () => {
        setOpen(true);
        setStatements([]);
        secureAxios
            .get(`${process.env.REACT_APP_SERVICE_PYTHON_API_URL}/user/statement?page=${page}`).then(response => {
              setOpen(false);
              setTotal(response.data?.totalPage)
              if (response.data?.statements.length > 0){
                setStatements(response.data?.statements);
              }else{
                setNoData(true)
              }
            }).catch(error => {
              setOpen(false);
              if (error.response.data?.non_field_errors){
                alert(error.response.data?.non_field_errors)
              }
              else{
                alert(error.response.data?.detail)
              }
              
            })
      }

      React.useEffect(() => {
        withdrawanDetails()
        return
      }, [page]);

      return (
      <>
      <IBackdrop isOpen={open} msg={"Signin progress"}/>
      {(!open && statements.length == 0) && <Typography style={{color: 'rgb(33, 43, 54)', fontSize: 12}} variant="subtitle">No Records Found</Typography>}
      <div style={{height: '1px', padding: '0px !important'}}></div>
      {statements.map((item, index) => {
        return (
            <Paper key={index} 
            className={classes.pan}
            style={{background: 'rgb(24, 26, 32)'}}
                sx={{
                p: 0,
                margin: 'auto',
                flexGrow: 1,
                marginTop: '30px'
                }}
            >
                <Grid container spacing={2}>
                <Grid item xs={12} sm container>
                    <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs>
                        <Demo>
                            <List dense={dense}>
                                <ListItem
                                    secondaryAction={
                                        <Button style={{
                                            borderRadius: 35,
                                            backgroundColor: "transparent",
                                            color: 'rgb(33, 43, 54)',
                                            fontSize: "14px",
                                            border: '0.5px solid rgb(33, 43, 54)',
                                        }} variant="contained">{item?.reason == 'withdrawan' && '-'}{item.coin_code.toLowerCase() == 'btt' ? item?.amount.toFixed(2) : item?.amount} {item.coin_code.includes('_') && !["nft_monthly", "nft_reddem"].includes(item.reason) ? item.coin_code.split("_")[0] : item.coin_code.replace("_", " ") }</Button>
                                    }
                                >
                                    <ListItemText
                                    className={classes.title}
                                    primary={get_formatter_text(item.reason)}
                                    secondary={
                                        <>
                                            <Typography style={{color: 'rgb(33, 43, 54)', fontSize: 12}} variant="subtitle2">{`${item?.note} ${get_formatter_text2(item.reason)} ${Moment(item.date_created).format('YYYY-MM-DD hh:mm:ss A')}`}</Typography>
                                        </>
                                    }
                                    secondaryTypographyProps={{style: text}}
                                    />
                                </ListItem>
                            </List>
                        </Demo>
                    </Grid>
                    </Grid>
                </Grid>
                </Grid>
            </Paper>
            )
        })}
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: '20px' }}>
        {statements.length > 0 && <Pagination page={page} onChange={handlePageChange} count={total} variant="outlined" color="primary" />}
      </div>      </>
      )
  }
  
  export default WithdrawalStateMent

