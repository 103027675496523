import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import WalletIcon from '@mui/icons-material/Wallet';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Typography from '@mui/material/Typography';
import btt from "../assets/btt.svg";
import usdt from "../assets/usdt.png";
import Avatar from '@mui/material/Avatar';

import StarRateIcon from '@mui/icons-material/StarRate';
import { makeStyles } from '@material-ui/styles';
import { useSelector, useDispatch} from 'react-redux';
import { SET_DRAWER } from '../redux/actions/types';
import { useNavigate } from "react-router-dom";

import { Link } from "react-router-dom";
import AddLinkIcon from '@mui/icons-material/AddLink';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Alert from '@mui/material/Alert';
import InfoIcon from '@mui/icons-material/Info';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import secureAxios from '../secureAxios';
import { LOGIN, LOGOUT, SET_ADMIN } from '../redux/actions/types';
import { useConnect, useDisconnect, useAccount, useChain, useEnsAvatar, useEnsName } from 'wagmi'


const useStyles = makeStyles({
  drawerPaper: {
    marginTop: "60px"
  }
});

function getIcon(coin_code) {
  if (coin_code === 'usdt'){
      return usdt
  }
  else{
      return btt
  }
}

const user_details = {
  "username": "YOUR_USERNAME",
  "balance": [
    {
      "code": "usdt",
      "balance": 0,
      "limit": 2
    },
    {
      "code": "btt",
      "balance": 0,
      "limit": 2
    },
    {
      "code": "star_nft",
      "balance": 0,
      "limit": 2
    }
  ],
  "totalIncome": 0,
  "totalBalance": 0
}

export default function Sidebar() {
  const classes = useStyles();
  const application = useSelector(state => ({
    ...state.application
  }));
  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = React.useState(user_details);
  let navigate = useNavigate();
  const { disconnect } = useDisconnect();



  const toggleDrawer = () => {
    dispatch({
      type: SET_DRAWER,
      payload: { isDrawerOpen: false}
    });
  }

  const Logout = () => {
    dispatch({
      type: LOGIN,
      payload: {
        profile: {},
        isAuthenticated: false,
        accessToken: {},
        groups: {},
        isDrawerOpen: false
      }
    });
    disconnect();
    navigate("/")
  }

  const Profiledetails = () => {
    secureAxios
        .get(`${process.env.REACT_APP_SERVICE_PYTHON_API_URL}/user/balance/header`).then(response => {
          if (response.data){
            setUserDetails(response.data);
          }
        }).catch(error => {
      })
  }

  React.useEffect(() => {
    Profiledetails()
    return
  }, []);

  
  const list = () => (
    <Box
      sx={{ flexGrow: 1, background: 'rgb(16, 20, 24)' }}
      role="presentation"
    >
      
      <Grid container >
        <Grid container xs={12} sx={{marginTop: '10px'}} spacing={2}  justifyContent="center" alignContent={'center'}>        
        {userDetails?.balance?.map((item, index) => {
          return (
            <Grid key={index} item xs={4} container justifyContent="center" alignItems="center">
              {item?.code != 'star_nft' && <Avatar sx={{ width: 12, height: 12, marginRight: 1 }} style={{ background: 'transparent', borderRadius: '0px' }}>
                <img style={{ width: 12 }} src={getIcon(item?.code)} alt={item?.code} />
              </Avatar>}
              {item?.code === 'star_nft' && <StarRateIcon sx={{width: 12, height: 12}}/>}
              <Typography variant="h2" fontWeight="bold" sx={{ fontSize: 12, textAlign: 'center' }}>
                {item?.balance.toFixed(2)}
              </Typography>
            </Grid>
          )
        })}
        
        <Grid item xs={10}>
          <Link onClick={toggleDrawer} to="/user/wallet">
          <Button
            sx={{
              background: '#05b4cc',
              color: '#fff !important',
              width: '100%',
            }}
            variant="contained"
            startIcon={<WalletIcon sx={{ color: '#fff !important' }}/>}
          >
            Wallet
          </Button>
          </Link>
        </Grid>
        <Grid item xs={10}>
          <Link onClick={toggleDrawer} to="/swap">
          <Button
            sx={{
              marginTop: '0px',
              background: '#05b4cc',
              color: '#fff !important',
              width: '100%',
            }}
            variant="contained"
            
          >
             NFT's Marketplace <Typography variant="h2" sx={{ fontSize: 10, textAlign: 'center', marginLeft: '10px', color: "#000 !important" }}>
          </Typography>
          </Button>
          </Link>
        </Grid>

        <Grid item xs={5}>
          <Link onClick={toggleDrawer} to="/home">
            <Button
              sx={{
                marginTop: '0px',
                background: '#05b4cc',
                color: '#fff !important',
                width: '100%',
              }}
              variant="contained"
            >
              Home
          </Button>
          </Link>
        </Grid>

        <Grid item xs={5}>
          <Link onClick={toggleDrawer} to="/users">
          <Button
            sx={{
              marginTop: '0px',
              background: '#05b4cc',
              color: '#fff !important',
              width: '100%',
            }}
            variant="contained"
          >
            My Team
          </Button>
            </Link>
          </Grid>

          <Grid item xs={5}>
          <Link onClick={toggleDrawer} to="/notifications">
            <Button
              sx={{
                marginTop: '0px',
                background: '#05b4cc',
                color: '#fff !important',
                width: '100%',
              }}
              variant="contained"
              startIcon={<NotificationsActiveIcon sx={{ color: '#fff !important' }}/>}
            >
              Notifications
            </Button>
          </Link>
          </Grid>

          <Grid item xs={5}>
          <Button
            sx={{
              marginTop: '0px',
              background: '#05b4cc',
              color: '#fff !important',
              width: '100%',
            }}
            variant="contained"
          >
            Reawrds
          </Button>
          </Grid>


          <Grid item xs={5}>
         
          <Button
            sx={{
              marginTop: '0px',
              background: '#05b4cc',
              color: '#fff !important',
              width: '100%',
              textAlign: 'center', // Center the text
              '& .MuiButton-startIcon, & .MuiButton-endIcon': {
                display: 'flex',
                alignItems: 'center',
              },
              '& .MuiButton-endIcon': {
                marginLeft: 'auto',
              },
            }}
            startIcon={<AddLinkIcon sx={{ color: "#fff !important" }} />}
            endIcon={<CopyToClipboard className='no_data_text' style={{cursor: 'pointer'}} text={'https://metadex.cc/signup?u='+userDetails?.username} onCopy={() => alert('Copied Successfully')}>
            <ContentCopyIcon sx={{ color: "#fff !important" }} /></CopyToClipboard>}
            variant="contained"
          >
            Copy my link
          </Button>


          </Grid>

          <Grid item xs={5}>
            <Link onClick={toggleDrawer} to="/transactions">
              <Button
                sx={{
                  marginTop: '0px',
                  background: '#05b4cc',
                  color: '#fff !important',
                  width: '100%',
                }}
                variant="contained"
              >
                Transactions
              </Button>
            </Link>
          </Grid>

        <Grid item xs={10}>
        <Alert variant="outlined" icon={<InfoIcon fontSize="inherit" />} sx={{background: "transparent"}} severity="info">
          <Typography variant="h2" sx={{ fontSize: 16, textAlign: 'left', marginLeft: '10px', color: "#05b4cc !important", display: 'flex', alignItems: 'center' }}>
            {userDetails?.username}
            <CopyToClipboard className='no_data_text' style={{cursor: 'pointer'}} text={userDetails?.username} onCopy={() => alert('Copied Successfully')}>
              <ContentCopyIcon sx={{ marginLeft: '5px', cursor: 'pointer', fill: '#fff' }}/>
            </CopyToClipboard>
          </Typography>
        </Alert>

        </Grid>

          <Grid item xs={10}>
          <Button
            sx={{
              marginTop: '10px',
              background: '#05b4cc',
              color: '#fff !important',
              width: '100%',
            }}
            variant="contained"
            onClick={Logout}
          >
            Disconnect
          </Button>
          </Grid>

        </Grid>
      </Grid>
        
      
    </Box>
  );

  return (
    <div>
        <SwipeableDrawer
          classes={{
            paper: classes.drawerPaper
          }}
          PaperProps={{
            sx: {
              width: '100%',
              maxWidth: '100%',
            },
          }}
          onClose={toggleDrawer}
          open={application.isDrawerOpen}
          onOpen={()=>{}} // Passing null

        >
          {list()}
        </SwipeableDrawer>
    </div>
  );
}