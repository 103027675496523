import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

import Divider from '@mui/material/Divider';




const WithdrawDialog = ({ open, onClose, onSubmit, isBtt, userDetails, balance, onUnlock }) => {
  const [amount, setAmount] = useState('');
  const [pin, setPin] = useState('');


  const handleClose = () => {
    setAmount('');
    setPin('');
    onClose();
  };

  const handleWithdraw = () => {
    // Perform withdrawal action
    const coinCode = isBtt ? 'btt': 'usdt';
    onSubmit({ amount, pin, coinCode  });
    // Optionally, you can reset the fields after submission
    setAmount('');
    setPin('');
  };

  const handleUnlock = (id) => {
    let PIN = prompt("Please enter pin:");
    if (PIN !== null) {
      if (PIN.trim() !== '') {
        onUnlock({id, PIN})
      } else {
        alert("Please enter the PIN")
      }
    } 
  }

  return (
    <Dialog className='dilog_popup' open={open} onClose={handleClose}>
      <DialogTitle>{isBtt ? 'BTT':'USDT'} Withdrawal Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To withdrawal please enter your amount
        </DialogContentText>

       {isBtt && <> <DialogContentText sx={{marginBottom: '20px'}}>
        ** Amounts are calculated as of today.
      </DialogContentText>

      <Grid container spacing={2} justifyContent={'center'}>
        <Grid item textAlign={'center'} xs={6}><Typography textAlign={'center'} variant={"body5"} className={"_amount"}>Available amount</Typography></Grid>
        <Grid item textAlign={'center'} xs={6}><Typography textAlign={'center'} variant={"body5"} className={"_amount"}>Locking Amount</Typography></Grid>
        <Grid item sx={{padding: '0px !important', margin: '5px 0px'}} xs={6}><Typography sx={{fontSize: '13px'}} textAlign={'center'}>{(balance?.balance - balance?.lockedBalance).toFixed(2)}</Typography></Grid>
        <Grid item sx={{padding: '0px !important', margin: '5px 0px'}} xs={6}><Typography sx={{fontSize: '13px'}} textAlign={'center'}>{balance?.lockedBalance.toFixed(2)}</Typography></Grid>
      </Grid>

      <Divider sx={{margin: '10px 0px'}}/>

      <TableContainer style={{marginTop: '10px'}} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">Package Name</TableCell>
            <TableCell align="center">Days Count</TableCell>
            <TableCell align="center">Fees</TableCell>
            <TableCell align="center">Amount</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {
            userDetails?.stakes?.map((item, index) => {
              return (
                  <TableRow key={index}>
                    <TableCell align="center">{item?.package_amount} {item?.name}</TableCell>
                    <TableCell align="center">{item?.current_days}</TableCell>
                    <TableCell align="center">{item?.fee}%</TableCell>
                    <TableCell align="center">{(item?.availableCoin - ((item?.availableCoin * item?.fee) / 100)).toFixed(2)}</TableCell>
                    <TableCell align="center"><Button onClick={() => handleUnlock(item?.id)}>Unlock</Button></TableCell>
                  </TableRow>
              );
            })
          }
          {userDetails?.stakes?.length === 0 && <TableRow key={0}><TableCell colSpan={5} textAlign={"center"} align="center">No records found</TableCell></TableRow>}
        </TableBody>
      </Table>
    </TableContainer>    </>}


        <TextField
          autoFocus
          margin="dense"
          id="amount"
          label="Amount"
          type="number"
          fullWidth
          variant="standard"
          value={amount}
          onChange={(event) => setAmount(event.target.value)}
        />
        <TextField
          margin="dense"
          id="pin"
          label="Secret Pin"
          type="password"
          fullWidth
          variant="standard"
          value={pin}
          onChange={(event) => setPin(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleWithdraw}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

export default WithdrawDialog;
