import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

import Divider from '@mui/material/Divider';




const ReddemDialog = ({ open, onClose, onSubmit, coinCode, name }) => {
  const [amount, setAmount] = useState('');
  const [pin, setPin] = useState('');


  const handleClose = () => {
    setAmount('');
    setPin('');
    onClose();
  };

  const handleWithdraw = () => {
    // Perform withdrawal action
    onSubmit({ amount, pin, coinCode  });
    // Optionally, you can reset the fields after submission
    setAmount('');
    setPin('');
  };


  return (
    <Dialog className='dilog_popup' open={open} onClose={handleClose}>
      <DialogTitle>{name} Reddem Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To reddem please enter your amount
        </DialogContentText>

        <TextField
          autoFocus
          margin="dense"
          id="amount"
          label="Amount"
          type="number"
          fullWidth
          variant="standard"
          value={amount}
          onChange={(event) => setAmount(event.target.value)}
        />
        <TextField
          margin="dense"
          id="pin"
          label="Secret Pin"
          type="password"
          fullWidth
          variant="standard"
          value={pin}
          onChange={(event) => setPin(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleWithdraw}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReddemDialog;
