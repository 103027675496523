import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Paper } from '@mui/material';


const ConvertDialog = ({ open, userDetails, onClose, onTransfer }) => {
  const [amount, setAmount] = useState('');
  const [pin, setPin] = useState('');

  // Calculate total amount
  const totalAmount = userDetails?.stakes?.reduce((total, item) => {
    return total + (item?.availableCoin  - ((item?.availableCoin * item?.fee) / 100));
  }, 0);


  const handleClose = () => {
    setAmount('');
    setPin('');
    onClose();
  };

  const handleTransfer = () => {
    // Perform transfer action
    onTransfer({ amount, pin });
    // Optionally, you can reset the fields after submission
    setAmount('');
    setPin('');
  };

  return (
    <Dialog className='dilog_popup' open={open} onClose={handleClose}>
      <DialogTitle>Convert BTT - USDT Confirmation</DialogTitle>
      <DialogContent>

      <DialogContentText>
        ** Amounts are calculated as of today.
      </DialogContentText>

      <TableContainer style={{marginTop: '10px'}} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">Dollar Package</TableCell>
            <TableCell align="center">Fee Percent</TableCell>
            <TableCell align="center">Amount You Received</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {
            userDetails?.stakes?.map((item, index) => {
              return (
                  <TableRow key={index}>
                    <TableCell align="center">{item?.package_amount}</TableCell>
                    <TableCell align="center">{item?.fee}%</TableCell>
                    <TableCell align="center">{(item?.availableCoin - ((item?.availableCoin * item?.fee) / 100)).toFixed(2)}</TableCell>
                  </TableRow>
              );
            })
          }
        </TableBody>

        <TableFooter>
          <TableRow>
            <TableCell align="center" colSpan={2}>Total</TableCell>
            <TableCell align="center">{totalAmount.toFixed(2)}</TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>


        <TextField
          margin="dense"
          id="amount"
          label="Amount"
          type="number"
          fullWidth
          variant="standard"
          value={amount}
          onChange={(event) => setAmount(event.target.value)}
        />

        <TextField
          margin="dense"
          id="pin"
          label="Secret Pin"
          type="password"
          fullWidth
          variant="standard"
          value={pin}
          onChange={(event) => setPin(event.target.value)}
        />

      </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleTransfer}>Submit</Button>
        </DialogActions>
      
    </Dialog>
  );
};

export default ConvertDialog;
