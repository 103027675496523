import logo from './logo.svg';
import './App.css';
import Routes from './routes';
import { useDispatch, useSelector} from 'react-redux';
import * as React from 'react';

import { LOGIN, LOGOUT, SET_ADMIN } from './redux/actions/types';

import { base, bsc, optimism } from 'wagmi/chains'
import { injected, metaMask, safe, walletConnect } from 'wagmi/connectors';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { WagmiProvider, http, createConfig } from 'wagmi'
import { getChainId } from '@wagmi/core';


export const config = createConfig({
  chains: [bsc],
  connectors: [
    injected()
  ],
  transports: {
    [bsc.id]: http(),
  },
});


const queryClient = new QueryClient()


function App() {
  return (
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
            <div className="App">
              <Routes/>     
            </div>
        </QueryClientProvider>
      </WagmiProvider>
  );
}

export default App;
