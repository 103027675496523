import {
    LOGIN,
    LOGOUT,
    SET_SHOW_BACKDROP,
    SET_SCREEN_SIZE,
    SET_ADMIN,
    SET_DRAWER
  } from '../actions/types';
  const initialState = {
    profile: null,
    isAuthenticated: null,
    accessToken: null,
    isMobile: true,
    isAdmin: false,
    groups: [],
    isDrawerOpen: false
  };
  const application = (state = initialState, action) => {
    switch (action.type) {
      case LOGIN:
        return {
          ...state,
          profile: action.payload.profile,
          isAuthenticated: action.payload.isAuthenticated,
          accessToken: action.payload.accessToken,
          groups: action.payload.groups
        };
      case LOGOUT:
        return {
          ...state,
          profile: '',
          isAuthenticated: false,
          accessToken: null,
          groups: []
        };
      case SET_SHOW_BACKDROP:
        return { ...state, showBackdrop: action.payload };
      case SET_SCREEN_SIZE:
        return { ...state, isMobile: action.payload.isMobile, width: action.payload.width};
      case SET_ADMIN:
        return { ...state, isAdmin: action.payload.isAdmin};
      case SET_DRAWER:
        return { ...state, isDrawerOpen: action.payload.isDrawerOpen};
      default:
        return state;
    }
  };
  
  export default application;