export const SET_SHOW_BACKDROP = 'SET_SHOW_BACKDROP';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const FETCH_GITHUB_DATA = 'FETCH_GITHUB_DATA';
export const FETCH_NETFLIX_DATA = 'FETCH_NETFLIX_DATA';
export const SET_SCREEN_SIZE = 'SET_SCREEN_SIZE';
export const SET_ADMIN = 'SET_ADMIN';
export const SET_DRAWER = 'SET_DRAWER';

export default {
  SET_SHOW_BACKDROP,
  LOGIN,
  LOGOUT,
  ACCESS_TOKEN,
  FETCH_GITHUB_DATA,
  FETCH_NETFLIX_DATA,
  SET_SCREEN_SIZE,
  SET_ADMIN,
  SET_DRAWER
};