import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useDispatch, useSelector} from 'react-redux';
import { SET_DRAWER } from '../redux/actions/types';
import { LOGIN, LOGOUT, SET_ADMIN } from '../redux/actions/types';
import mainlogo from '../assets/rocket.png';
import btt from '../assets/btt.svg'
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom/dist';

import ButtonBase from '@mui/material/ButtonBase';
import { useConnect, useDisconnect, useAccount, useChain, useEnsAvatar, useEnsName } from 'wagmi'



export default function Header() {

  const application = useSelector(state => ({
    ...state.application
  }));
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const { isConnected } = useAccount();
  const { disconnect } = useDisconnect();
  const navigate = useNavigate();




 


  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const toggleDrawer = () => {
    dispatch({
      type: SET_DRAWER,
      payload: { isDrawerOpen: !application.isDrawerOpen}
    });
  }

  React.useEffect(() => {
    if (!isConnected) {
      disconnect();
      navigate('/');
    }
  }, [isConnected]);
 
    

  return (
    <Box sx={{ flexGrow: 1 }} style={{paddingBottom: '30px',  overflow: 'hidden'}}>
      <AppBar position="fixed" style={{background: 'rgb(16, 20, 24)', overflow: 'hidden'}}>
        <Toolbar>
            <Grid container direction="row">
            <Grid item>
              <Avatar sx={{ width: 140, marginLeft: '15px' }}  style={{background: 'transparent', borderRadius: 0}}>
                  <img style={{width: 140}} src={mainlogo} alt="fireSpot"/>
              </Avatar>
            </Grid>
          </Grid>


          {/* 
           */}
          {auth && (
            <div>
              <ButtonBase onClick={toggleDrawer}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
              </ButtonBase>
              
            </div>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
