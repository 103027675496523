import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default function IBackdrop({isOpen, msg}) {
 

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isOpen}
      >
        <CircularProgress color="inherit" />
        <Typography style={{color: '#fff', fontSize: 12, display: 'block', marginLeft: '10px'}} variant="caption">Loading ...</Typography>
      </Backdrop>
    </div>
  );
}